import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { fetchSearchAutocomplete } from "../functions/fetch";

export const useSearch = () => {
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();

    const fetchSuggestions = useCallback(
        debounce(async (query) => {
            if (query.length >= 3) {
                const results = await fetchSearchAutocomplete(query);
                setSuggestions(results);
            } else {
                setSuggestions([]);
            }
        }, 300),
        []
    );

    useEffect(() => {
        fetchSuggestions(query);
    }, [query, fetchSuggestions]);

    const handleSubmit = useCallback(
        (e, searchQuery = query) => {
            e.preventDefault();
            if (searchQuery) {
                navigate(`/sport/search?query=${searchQuery}`);
            }
        },
        [query, navigate]
    );

    return {
        handleSubmit,
        suggestions,
        setQuery,
        query
    }
}