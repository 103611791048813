import DropdownButtonsList from "../DropdownButtonsList/DropdownButtonsList";
import { MoreVerticalSVG } from "../../images/SVGAssets";
import Counter from "../Controls/Counter";
import ButtonWithIconLeft from "../Controls/ButtonWithIconLeft";
import ButtonIcon from "../Controls/ButtonIcon";
import { HeartSmallSVG } from "../../images/SVGAssets";
import { TrashSmallSVG } from "../../images/SVGAssets";
function MobileSportItemDropdown({counter, setCounter, handleFavoriteClick, handleDeleteClick, onCounterUpdate}) {

    return (
        <DropdownButtonsList
            icon={<ButtonIcon icon={<MoreVerticalSVG />}/>}
        >
            <div className="flex justify-between w-64">
                <p className="flex justify-start items-center">Change Amount</p>
                <Counter
                    counter={counter}
                    setCounter={setCounter}
                    onCounterUpdate={onCounterUpdate}
                />
            </div>
            <hr className='border-t border-gray-lidabro-divider'/>
            <div className="flex justify-between items-center">
                <ButtonWithIconLeft
                    icon={<HeartSmallSVG />}
                    name={"Save"}
                    callback={handleFavoriteClick}
                    isSmallText={false}
                />
                <ButtonWithIconLeft
                    icon={<TrashSmallSVG />}
                    name={"Delete"}
                    callback={handleDeleteClick}
                    isSmallText={false}
                />    
            </div>
        </DropdownButtonsList>
    )
}

export default MobileSportItemDropdown;