import { useState } from "react";
import { GlobeSVG } from "../../images/SVGAssets";
import { useTranslation } from "react-i18next";
import { supportedLanguages } from "../../i18n";
import classNames from "classnames";

function LanguageSport({ isWeb = true }) {
    const [isHovered, setIsHovered] = useState(false);
    const { i18n, t } = useTranslation();

    const handleClick = (event) => {
        i18n.changeLanguage(event.target.id);
    };

    const languageClasses = classNames(
        "flex px-[14px] py-[10px] gap-3 font-secondary text-sm not-italic font-medium leading-5",
        isWeb ? "text-white" : "text-gray-lidabro-breadcrumb"
    );

    return (
        <div
            className={languageClasses}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <p className="block xl:hidden">{t(i18n.language + "Short")}</p>
            <p className="hidden xl:block">{t(i18n.language + "Full")}</p>
            {isHovered && (
                <>
                    {Object.entries(supportedLanguages)
                        .filter(([lng]) => lng !== i18n.language)
                        .map(([lng]) => (
                            <div key={lng} className="cursor-pointer">
                                <p
                                    id={lng}
                                    className="block xl:hidden"
                                    onClick={handleClick}
                                >
                                    {t(lng + "Short")}
                                </p>
                                <p
                                    id={lng}
                                    className="hidden xl:block"
                                    onClick={handleClick}
                                >
                                    {t(lng + "Full")}
                                </p>
                            </div>
                        ))}
                </>
            )}
            <GlobeSVG color={isWeb ? "#fff" : "#475467"} />
        </div>
    );
}

export default LanguageSport;
