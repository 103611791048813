import { useNavigate } from "react-router-dom";
import { fetchPathByCategoryName } from "../../functions/fetch";
import { Heart3SVG } from "../../images/SVGAssets";
import useSuccessMessage from "../../hook/useSuccessMessage";
import { FavoriteContext } from "../../contexts/FavoriteContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { truncateStr } from "../../functions/common";
import ProgressiveImage from "../ProgressiveImage/ProgressiveImage";

function ItemCardSport({ item, loading = false, callback = () => {} }) {
    const { toggleItem, isItemInFavorites } = useContext(FavoriteContext);
    const navigate = useNavigate();
    const { getSuccessMessage, trigger } = useSuccessMessage({
        type: "favorites",
    });
    const { i18n, t } = useTranslation();

    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        toggleItem(item);
        if(!isItemInFavorites(item)) {
            trigger(item);
        }
    };

    if (loading) {
        return (
            <div className="flex flex-col min-w-[160px] h-[250px] relative cursor-default animate-pulse">
                <div className="h-[170px] bg-gray-300"></div>
                <div className="flex flex-col py-3">
                    <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                    <div className="h-3 bg-gray-300 rounded w-full mb-1"></div>
                    <div className="h-3 bg-gray-300 rounded w-1/2"></div>
                </div>
                <div className="absolute top-[10px] right-[11px]">
                    <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
                </div>
            </div>
        );
    }

    return (
        <>
            {item && (
                <div
                    className="flex flex-col min-w-[160px] h-[250px] relative cursor-pointer"
                    onClick={() => {
                        navigate(`/sport/items/${item.article}`);
                        callback();
                    }}
                >
                    <div className="flex items-center justify-center h-[170px]">
                        {item.images.length > 0 ? (
                            <div className="h-[170px] max-h-[170px] max-w-[160px] flex justify-center">
                                <ProgressiveImage
                                    highQualitySrc={item.images[0].url}
                                    alt={item.images[0].altText}
                                />
                            </div>
                        ) : (
                            <img
                                className="h-full"
                                src="https://via.placeholder.com/160x170?text=No+image"
                                alt={item.name}
                            />
                        )}
                    </div>
                    <div className="flex flex-col py-3">
                        {item.hasOwnProperty("discountPrice") &&
                        item.discountPrice ? (
                            <div className="flex items-center gap-2">
                                <p className="font-secondary text-sm not-italic font-semibold leading-5 text-blue-lidabro-header">
                                    ₪ {item.discountPrice.price}
                                </p>
                                <p className="font-secondary text-xs not-italic font-semibold leading-4 text-gray-lidabro-text-2 line-through">
                                    ₪ {item.actualPrice.price}
                                </p>
                            </div>
                        ) : (
                            <p className="font-secondary text-sm not-italic font-semibold leading-5 text-blue-lidabro-header">
                                ₪ {item.actualPrice.price}
                            </p>
                        )}
                        <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-black-lidabro text-nowrap">
                            {i18n.language === "en"
                                ? truncateStr(item.name, 20)
                                : truncateStr(
                                      item[`name_${i18n.language}`],
                                      20
                                  ) || truncateStr(item.name, 20)}
                        </p>
                        <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-gray-lidabro-dark">
                            {item.type ? item.type : "NO TYPE"}
                        </p>
                    </div>
                    <div
                        className="flex flex-col absolute top-[10px] right-[11px]"
                        onClick={(e) => {handleFavoriteClick(e)}}
                    >
                        <Heart3SVG filled={isItemInFavorites(item)} />
                    </div>
                    {getSuccessMessage()}
                </div>
            )}
        </>
    );
}

export default ItemCardSport;
