export function sentenceCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatKey(key) {
    return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

export function truncateStr(str, length) {
    return str.length > length ? str.slice(0, length) + "..." : str;
}

export function modifyUrlPrefix(url, prefix) {
    const urlParts = url.split("/");
    const fileName = urlParts.pop(); // Extract the file name
    const modifiedFileName = prefix + fileName; // Add the prefix
    urlParts.push(modifiedFileName); // Reconstruct the URL
    return urlParts.join("/");
}
