import { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../HOC/Language";
import { useTranslation } from "react-i18next";
import { submitContacts } from "../../../functions/fetch";
import { isEmail, isEmpty } from "validator";
import Icon from "../Shared/Icon";
import { SmileIcon, EditIcon } from "../Shared/SVGComponents";

function ContactUs(props) {
    const defaultFormData = {
        email: "",
        fullName: "",
        phoneNumber: "",
        companyName: "",
    };

    const validationStatusDefault = {
        email: false,
        fullName: false,
    };

    const [formData, setFormData] = useState(defaultFormData);
    const [formState, setFormState] = useState("default");
    const [validationStatus, setValidationStatus] = useState(
        validationStatusDefault
    );
    const {
        language,
        rowDirection,
        colDirection,
        textDirection,
        itemsReverseDirection,
        selfDirection,
    } = useContext(LanguageContext);
    const { t } = useTranslation();

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name == "phoneNumber") {
            value = value.replace(/[^0-9]/g, "");
        }
        setValidationStatus(validationStatusDefault);
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleBack = (e) => {
        setFormState("default");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEmpty(formData.email) && isEmpty(formData.fullName)) {
            return;
        }

        if (isEmpty(formData.fullName)) {
            console.log("Name is blank");
            setValidationStatus((prevState) => ({
                ...prevState,
                fullName: true,
            }));
        }

        if (!isEmail(formData.email)) {
            console.log("Not email");
            setValidationStatus((prevState) => ({
                ...prevState,
                email: true,
            }));
        }

        if (isEmail(formData.email) && !isEmpty(formData.fullName)) {
            let successful = await submitContacts(formData);
            if (successful) {
                setFormData(defaultFormData);
                setFormState("submitted");
            } else {
                console.log("Not email or empty name"); //TODO: Add validation error
                setFormState("error");
            }
        }
    };

    const renderForm = (formState) => {
        switch (formState) {
            case "default":
                return (
                    <>
                        <div
                            className={`flex flex-col ${textDirection()} lg:gap-6 flex-[1_0_0%]`}
                        >
                            <div
                                className={`flex flex-col ${rowDirection(
                                    "lg"
                                )} lg:items-center gap-9`}
                            >
                                <Icon
                                    image={<SmileIcon />}
                                    bgColor="bg-white-lidabro bg-opacity-25"
                                />
                                <p className="text-2xl">{t("Let's meet")}</p>
                            </div>
                            <p>
                                {t("Please provide your contact information")}
                            </p>
                        </div>
                        <div>
                            <hr className="h-full border-r border-white border-opacity-20 rounded-sm" />
                        </div>
                        <div className="flex flex-[1_0_0%]">
                            <form
                                className={`w-full flex flex-col ${itemsReverseDirection()} gap-3`}
                                onSubmit={handleSubmit}
                            >
                                <input
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    type="text"
                                    className={`w-full px-5 py-2.5 rounded-3xl outline-none bg-opacity-20 bg-white border border-white border-opacity-50 ${textDirection()} placeholder:text-white placeholder:font-main placeholder:text-sm placeholder:font-medium`}
                                    placeholder={t("Name*")}
                                />
                                {validationStatus["fullName"] && (
                                    <p
                                        className={`px-3 py-1 text-sm text-white ${selfDirection()}`}
                                    >
                                        Name can not be blank
                                    </p>
                                )}
                                <input
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    type="text"
                                    className={`w-full px-5 py-2.5 rounded-3xl outline-none bg-opacity-20 bg-white border border-white border-opacity-50 ${textDirection()} placeholder:text-white placeholder:font-main placeholder:text-sm placeholder:font-medium`}
                                    placeholder={t("Email Address*")}
                                />
                                {validationStatus["email"] && (
                                    <p
                                        className={`px-3 py-1 text-sm text-white ${selfDirection()}`}
                                    >
                                        Email is invalid
                                    </p>
                                )}
                                <input
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    type="text"
                                    className={`w-full px-5 py-2.5 rounded-3xl outline-none bg-opacity-20 bg-white border border-white border-opacity-50 ${textDirection()} placeholder:text-white placeholder:font-main placeholder:text-sm placeholder:font-medium`}
                                    placeholder={t("Phone Number")}
                                />
                                <input
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    type="text"
                                    className={`w-full px-5 py-2.5 rounded-3xl outline-none bg-opacity-20 bg-white border border-white border-opacity-50 ${textDirection()} placeholder:text-white placeholder:font-main placeholder:text-sm placeholder:font-medium`}
                                    placeholder={t("Company")}
                                />
                                <button
                                    type="submit"
                                    className="w-full lg:w-fit px-5 py-3 bg-white rounded-3xl font-main text-sm font-medium text-blue-lidabro-landing-dark text-nowrap"
                                >
                                    {t("Submit")}
                                </button>
                            </form>
                        </div>
                    </>
                );
            case "submitted":
                return (
                    <>
                        <div className="w-full flex flex-col items-center gap-9">
                            <Icon
                                image={<SmileIcon />}
                                bgColor="bg-white-lidabro bg-opacity-25"
                            />
                            <p className="text-2xl">
                                {t("ContactUs Successful Body")}
                            </p>
                            <button
                                type="button"
                                className="w-full lg:w-fit px-5 py-3 bg-white rounded-3xl font-main text-sm font-medium text-blue-lidabro-landing-dark text-nowrap"
                                onClick={handleBack}
                            >
                                {t("Return to Form")}
                            </button>
                        </div>
                    </>
                );
            case "error":
                return (
                    <>
                        <div className="w-full flex flex-col items-center gap-9">
                            <Icon
                                image={<EditIcon />}
                                bgColor="bg-white-lidabro bg-opacity-25"
                            />
                            <p className="text-2xl">
                                {t("ContactUs Error Header")}
                            </p>
                            <p className="text-2xl">
                                {t("ContactUs Error Body")}
                            </p>
                            <button
                                type="button"
                                className="w-full lg:w-fit px-5 py-3 bg-white rounded-3xl font-main text-sm font-medium text-blue-lidabro-landing-dark text-nowrap"
                                onClick={handleBack}
                            >
                                {t("Return to Form")}
                            </button>
                        </div>
                    </>
                );
        }
    };

    useEffect(() => {
        return () => {};
    }, [formState]);

    return (
        <div
            id="ContactUs"
            className={`flex flex-col ${rowDirection(
                "lg"
            )} gap-6 px-14 py-12 rounded-3xl bg-blue-lidabro-landing-default font-main text-xl font-normal text-white lg:mb-[140px] lg:mx-40 scroll-mt-[150px] lg:scroll-mt-[300px]`}
        >
            {renderForm(formState)}
        </div>
    );
}

export default ContactUs;
