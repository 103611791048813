import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BasketListItems from "./BasketListItems";
import InputFieldDashed from "../Inputs/InputFieldDashed";
import BasketSubtotal from "./BasketSubtotal";
import SportItemsCarousel from "../ItemsCarousel/SportItemsCarousel";
import StreamSeeLiveHelp from "../Stream/StreamSeeLiveHelp";
import ButtonIcon from "../Controls/ButtonIcon";
import { TrashSVG, ShareSVG, XClose2SVG } from "../../images/SVGAssets";
import {
    fetchSportRelatedItems,
    fetchBasketBySession,
    deleteBasket,
    placeAnOrderBodyMania,
    createAnOrder,
} from "../../functions/fetch";
import { BasketContext } from "../../contexts/BasketContext";
import { useContext } from "react";

function BasketSport(params) {
    //TODO: SkeletonUI
    const { basketItems, refreshBasketItems, loading, error } =
        useContext(BasketContext);
    const emptyBasket = { promocode: "" };
    const [basket, setBasket] = useState(emptyBasket);
    const [relatedItems, setRelatedItems] = useState([]);
    const [promocode, setPromocode] = useState(basket.promocode);
    const { t } = useTranslation();

    const fetchRelatedItems = async () => {
        const fetchedBasket = await fetchBasketBySession();
        const fetchedRelatedItems = await fetchSportRelatedItems();
        setBasket(fetchedBasket);
        setRelatedItems(fetchedRelatedItems);
    };

    useEffect(() => {
        fetchRelatedItems();
    }, []);

    return (
        <>
            {loading ? (
                <div>{t("Loading")}</div>
            ) : (
                <div className="flex flex-col w-full md:max-w-[650px]">
                    <div className="max-w-full hidden sm:flex items-center justify-between pt-4 pb-6 px-[45px] bg-white">
                        <p className="font-main text-2xl font-medium text-gray-lidabro-text">
                            {t("Shopping Basket")}
                        </p>
                        <div className="flex items-center">
                            <ButtonIcon
                                icon={<TrashSVG />}
                                callback={async() => {
                                    await deleteBasket();
                                    await refreshBasketItems();
                                    setBasket(emptyBasket);
                                }}
                            />
                            <ButtonIcon icon={<ShareSVG />} />
                            <ButtonIcon
                                icon={<XClose2SVG />}
                                callback={params.closeBasket}
                            />
                        </div>
                    </div>

                    <div className="max-w-full flex flex-col gap-3 px-3 sm:px-[45px] pb-3 sm:pb-12 bg-white">
                        <div className="flex flex-col gap-3 sm:gap-6 rounded-[15px] sm:px-6 sm:py-6 sm:bg-gray-lidabro-select-background">
                            <BasketListItems items={basketItems} />
                            <InputFieldDashed
                                id={"promocode"}
                                name={"promocode"}
                                placeholder={t("Add Promocode")}
                                value={promocode}
                                onChange={setPromocode}
                            />
                            <div className="border-b border-gray-lidabro-border-3"></div>
                            <BasketSubtotal items={basketItems} />
                            <button
                                className="flex items-center justify-center px-[22px] py-4 rounded-lg bg-blue-lidabro-button md:bg-gray-lidabro-breadcrumb shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] font-secondary text-base font-semibold text-white text-opacity-90"
                                onClick={() => {
                                    params.openRedirectNotification();
                                    placeAnOrderBodyMania(basketItems).then(
                                        (data) => {
                                            if (data.cart_url) {
                                                params.setRedirectUrl(
                                                    data.cart_url
                                                );
                                                createAnOrder(
                                                    basketItems,
                                                    promocode
                                                );
                                            }
                                        }
                                    );
                                }}
                            >
                                {t("Place an order")}
                            </button>
                        </div>
                        <div className="flex flex-col sm:flex-col-reverse gap-6">
                            <div className="flex sm:hidden">
                                <SportItemsCarousel
                                    title={"You may like"}
                                    items={relatedItems}
                                    callback={params.closeBasket}
                                />
                            </div>
                            <div className="hidden sm:flex">
                                <SportItemsCarousel
                                    title={"Related Products"}
                                    items={relatedItems}
                                    callback={params.closeBasket}
                                />
                            </div>
                            <StreamSeeLiveHelp
                                eyezon={{
                                    id: "232",
                                    target: "",
                                    title: "Customer Basket",
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BasketSport;
