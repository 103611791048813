function Counter({ counter, setCounter, onCounterUpdate = null }) {
    function changeCounter(action) {
        switch (action) {
            case "add":
                setCounter(counter + 1);
                if (onCounterUpdate) {
                    onCounterUpdate(counter + 1);
                }
                break;

            case "subtract":
                if (counter > 1) {
                    setCounter(counter - 1);
                    if (onCounterUpdate) {
                        onCounterUpdate(counter - 1);
                    }
                }
                break;
        }
    }

    return (
        <div className="flex">
            <div className="flex px-3 py-[10px] gap-6 items-center rounded-lg bg-white">
                <button
                    id="subtract"
                    className="flex w-5 h-5 px-[6px] items-center justify-center rounded-[5px] font-secondary text-sm font-medium text-gray-lidabro-text-2"
                    onClick={(e) => changeCounter(e.target.id)}
                >
                    -
                </button>
                <p className="font-secondary text-sm font-semibold text-gray-lidabro-breadcrumb">
                    {counter}
                </p>
                <button
                    id="add"
                    className="flex w-5 h-5 px-[6px] items-center justify-center rounded-[5px] font-secondary text-sm font-medium text-gray-lidabro-text-2"
                    onClick={(e) => changeCounter(e.target.id)}
                >
                    +
                </button>
            </div>
        </div>
    );
}

export default Counter;
