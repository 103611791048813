import { useTranslation } from "react-i18next";
import { EyeSVG, DotSVG } from "../../images/SVGAssets";

function StreamSeeLive({ eyezon, item }) {
    const { i18n, t } = useTranslation();
    //TODO: 1.Availability
    //TODO: 2.Streamer Time
    return (
        <>
            <div className="flex flex-col p-4 gap-4 rounded-lg bg-white">
                {/* <button
                    data-eyezon={eyezon ? eyezon.id : undefined}
                    data-eyezon-title={eyezon ? eyezon.title : undefined}
                    className={`flex items-center justify-center gap-[10px] py-4 px-[22px] rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-lg font-semibold ${
                        eyezon ? "" : "opacity-50 cursor-not-allowed"
                    }`}
                    disabled={!eyezon}
                > */}
                <button
                    data-eyezon={i18n.language === "en" ? "232" : "235"}
                    data-eyezon-title={item.name}
                    data-eyezon-target={item.ean}
                    data-eyezon-put-in-cart
                    className={`flex items-center justify-center gap-[10px] py-4 px-[22px] rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-lg font-semibold`}
                    onClick={() => {
                        ym(98895916, "reachGoal", "StreamOn");
                    }}
                >
                    <EyeSVG fill="white" />
                    {t("See live")}
                </button>
                <div className="flex gap-2 items-center font-secondary text-sm font-medium text-gray-lidabro-select-remove">
                    <div className="flex gap-1 items-center">
                        <DotSVG />
                        <p className="text-[#47CD89]">{t("Available")}</p>
                    </div>
                    <p className="text-center overflow-x-auto text-nowrap">
                        Sa-Mo 9:30 AM - 8:30 PM Jerusalem Time
                    </p>
                </div>
            </div>
        </>
    );
}

export default StreamSeeLive;
