import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronRight2SVG } from "../../images/SVGAssets";
import { fetchCategoryChildrenByUrl, fetchBreadcrumbByCategoryUrl } from "../../functions/fetch";
import LanguageSport from "../NavMenu/LanguageSport";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const excludedSteps = ['search'];

function preparePath(rawPath) {
    const path = rawPath
    .split('/')
    .filter((step) => step && !excludedSteps.includes(step))
    .join('/');

    return `/${path}`
}

function MobileMenu(params) {
    const { t } = useTranslation();
    const [descendantCategories, setDescendantCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [breadcrumbPath, setBreadcrumbPath] = useState([]);
    const path = location.pathname;

    const fetchBreadcrumbsPath = async () => {
        setLoading(true);
        let fetchedBreadcrumb;
        if (location.pathname.includes("/brands/")) {
            fetchedBreadcrumb = await fetchBreadcrumbByBrandUrl(location.pathname);
        } else if (location.pathname.includes("/search")) {
            fetchedBreadcrumb = await fetchBreadcrumbByCategoryUrl("/sport");
        } else {
            fetchedBreadcrumb = await fetchBreadcrumbByCategoryUrl(
                location.pathname
            );
        }
        setBreadcrumbPath(fetchedBreadcrumb);
    };

    useEffect(() => {
        const fetchDescendantCategories = async () => {
            let fetchedDescendantCategories = await fetchCategoryChildrenByUrl(preparePath(path));
            if (fetchedDescendantCategories.children.length > 0) {
                window.localStorage.setItem('fetchedDescendantCategories', JSON.stringify(fetchedDescendantCategories))
            }
            if(fetchedDescendantCategories.children.length === 0) {
                fetchedDescendantCategories = JSON.parse(window.localStorage.getItem('fetchedDescendantCategories'))
            }

            setDescendantCategories(fetchedDescendantCategories);
            setLoading(false);
        };
        fetchDescendantCategories();
        fetchBreadcrumbsPath();
    }, [path]);

    const handleClick = (url, childrenAmount) => {
        if(childrenAmount === 0) {
            params.closeMenu()
        }
        navigate(url);
    }

    return (
        <>
            {loading ? (
                <div>{t("Loading")}...</div>
            ) : (
                <>
                    <div className="w-full flex flex-col px-6 py-10">
                        {(breadcrumbPath.length > 1) && <Breadcrumbs aloneBcClasses='px-1 py-0 justify-between' path={breadcrumbPath} />}
                        <div className="flex flex-col gap-12">
                            {descendantCategories.children.map((category) => {
                                return (
                                    <div
                                        key={category._id}
                                        className="flex flex-col font-secondary text-sm font-medium text-gray-lidabro-text"
                                    >
                                        <p
                                            className="text-lg px-[10px] py-[9px]"
                                            onClick={() => {handleClick(category.url, category.children.length)}}
                                        >
                                            {t(category.name)}
                                        </p>
                                        {category.children.map((category) => {
                                            return (
                                                <div
                                                    key={category._id}
                                                    className="flex items-center gap-3 px-[10px] py-[9px]  border-b border-gray-lidabro-border-2"
                                                    onClick={() => {handleClick(category.url, category.children.length)}}
                                                >
                                                    <p className="flex-grow">
                                                        {t(category.name)}
                                                    </p>
                                                    <div>
                                                        <ChevronRight2SVG />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <LanguageSport isWeb={false} />
                    </div>
                </>
            )}
        </>
    );
}

export default MobileMenu;
