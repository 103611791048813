import classNames from "classnames";

function ButtonWithIconLeft({ name, icon, callback, isSmallText = true}) {

    const textClasses = classNames(
        "font-secondary font-normal  text-gray-lidabro-breadcrumb text-nowrap",
        {
            "text-xs leading-[18px]": isSmallText,
            "pt-1 pb-1 pr-2 pl-2": !isSmallText
        }
    );
    return (
        <div
            className="flex flex-row items-center cursor-pointer p-[5px] gap-[3px]"
            onClick={callback}
        >
            {icon}
            <p className={textClasses}>
                {name}
            </p>
        </div>
    );
}

export default ButtonWithIconLeft;
