import React, { createContext, useState, useEffect } from "react";
import {
    fetchFavoriteItemsBySession,
    addItemToFavoriteByArticle,
    deleteItemFromFavoriteByArticle,
} from "../functions/fetch";

export const FavoriteContext = createContext();

export const FavoriteProvider = ({ children }) => {
    const [favoriteItems, setFavoriteItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadFavoriteItems = async () => {
        try {
            setLoading(true); // Start loading when refreshing
            const items = await fetchFavoriteItemsBySession();
            setFavoriteItems(items);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false); // End loading
        }
    };

    useEffect(() => {
        loadFavoriteItems();
    }, []);

    const toggleItem = async (item) => {
        try {
            const isFavorite = favoriteItems.some(
                (favItem) => favItem.item.article === item.article
            );

            if (isFavorite) {
                await deleteItemFromFavoriteByArticle(item);
                setFavoriteItems((prevFavorites) =>
                    prevFavorites.filter(
                        (favItem) => favItem.item.article !== item.article
                    )
                );
            } else {
                const newFavoriteItem = await addItemToFavoriteByArticle(item);
                setFavoriteItems((prevFavorites) => [
                    ...prevFavorites,
                    newFavoriteItem,
                ]);
            }
        } catch (err) {
            console.error("Failed to toggle favorite status:", err);
        }
    };

    const refreshFavoriteItems = async () => {
        await loadFavoriteItems();
    };

    const isItemInFavorites = (item) => {
        return favoriteItems.some(
            (favItem) => favItem.item.article === item.article
        );
    };

    return (
        <FavoriteContext.Provider
            value={{
                favoriteItems,
                refreshFavoriteItems,
                isItemInFavorites,
                toggleItem,
                loading,
                error,
            }}
        >
            {children}
        </FavoriteContext.Provider>
    );
};
