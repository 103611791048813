import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusSVG, MinusSVG } from "../../images/SVGAssets";

function ItemDescriptionSport({ item, collapsable = true }) {
    const [opened, SetOpened] = useState(collapsable ? false : true);
    const { i18n, t } = useTranslation();

    return (
        <>
            <div
                className={`flex items-center justify-between p-[6px] ${
                    collapsable
                        ? "cursor-pointer border-b-[.8px] border-gray-lidabro-border-3"
                        : ""
                }`}
                onClick={() => {
                    if (collapsable) {
                        SetOpened(!opened);
                    }
                }}
            >
                <p className="font-secondary text-sm md:text-base font-medium md:font-semibold leading-5 md:leading-6 text-gray-lidabro-breadcrumb md:text-gray-lidabro-breadcrumb-bold">
                    {t("Description")}
                </p>
                {collapsable ? (
                    <div>{opened ? <MinusSVG /> : <PlusSVG />}</div>
                ) : null}
            </div>
            {opened ? (
                <div
                    className={`px-[6px] py-2 ${
                        collapsable
                            ? "border-b-[.8px] border-gray-lidabro-border-3"
                            : ""
                    }`}
                >
                    <p className="font-secondary text-xs md:text-sm font-medium leading-[18px] md:font-normal md:leading-5 text-gray-lidabro-breadcrumb md:text-gray-lidabro-breadcrumb-bold">
                        {i18n.language === "en"
                            ? item.description
                            : item[`description_${i18n.language}`] ||
                              item.description}
                    </p>
                </div>
            ) : null}
        </>
    );
}

export default ItemDescriptionSport;
