import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonWithIconRight from "../Controls/ButtonWithIconRight";
import { ArrowRight3SVG } from "../../images/SVGAssets";

function CuratedCollectionItem(params) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigate = (url) => {
        navigate(url);
    };

    return (
        <div className="flex flex-col min-w-[300px] max-w-[300px] min-h-[300px] max-h-[300px]">
            <div
                className="flex grow cursor-pointer"
                onClick={() => handleNavigate(params.link)}
            >
                <img
                    className="w-full h-[230px] object-cover object-[30%_70%] filter grayscale hover:grayscale-0 transition ease duration-300"
                    src={params.image}
                    alt={params.title}
                />
            </div>
            <div className="flex justify-between py-[13px]">
                <p className="font-secondary text-sm not-italic font-semibold leading-5 text-black text-nowrap">
                    {params.title}
                </p>
                <div onClick={() => handleNavigate(params.link)}>
                    <ButtonWithIconRight
                        name={t("Shop Now")}
                        icon={<ArrowRight3SVG />}
                    />
                </div>
            </div>
        </div>
    );
}

export default CuratedCollectionItem;
