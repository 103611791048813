import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const supportedLanguages = {
    en: { nativeName: "English" },
    ru: { nativeName: "Русский" },
};

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "en",
        supportedLngs: Object.keys(supportedLanguages),
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        interpolation: {
            escapeValue: false,
        },
        pluralSeparator: "_",
    });

export default i18n;
