import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchBrandsByUrl } from "../../functions/fetch";

function BrandCarousel(params) {
    const location = useLocation(); // Track the current location
    const [loading, setLoading] = useState(true);
    const [brands, setBrands] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchBrands = async () => {
        setLoading(true);
        const fetchedBrands = await fetchBrandsByUrl(location.pathname);
        setBrands(fetchedBrands);
        setLoading(false);
    };

    useEffect(() => {
        fetchBrands();
    }, [location.pathname]);

    const handleNavigate = (url) => {
        navigate(url);
    };

    return (
        <>
            {loading ? (
                <div>Loading</div>
            ) : (
                <div className="flex flex-col px-3 md:px-12 py-[10px] md:py-10 xl:px-[180px] gap-[5px]">
                    <div className="flex px-[10px] items-center justify-between">
                        <h4 className="block md:hidden font-secondary text-sm not-italic font-semibold leading-5 text-black-lidabro">
                            {t("Brands")}
                        </h4>
                        <h4 className="hidden md:block font-secondary text-lg not-italic font-medium leading-7 text-black-lidabro">
                            {t("Browse by Brands")}
                        </h4>
                    </div>
                    <div className="flex gap-[16px] xl:gap-8 overflow-x-auto scrollbar-none">
                        {brands.map((brand) => (
                            <div
                                key={brand._id}
                                className="flex items-center justify-center min-w-[150px] min-h-[70px] cursor-pointer"
                                onClick={() => handleNavigate(brand.url)}
                            >
                                <img
                                    className="min-h-[70px] max-h-[70px] w-auto"
                                    src={brand.image}
                                    alt={brand.name}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default BrandCarousel;
