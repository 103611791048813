import { useTranslation } from "react-i18next";
import CuratedCollectionItem from "../CuratedCollectionItem/CuratedCollectionItem";
import { fetchCuratedCollection } from "../../functions/fetch";

function CuratedCollection(params) {
    const collections = fetchCuratedCollection();
    const { t } = useTranslation();

    return (
        <div className="flex flex-col px-3 md:px-12 py-[10px] md:py-10 gap-[5px] xl:px-[180px]">
            <div className="flex px-[10px] items-center justify-between">
                <h4 className="font-secondary text-sm md:text-lg not-italic font-semibold md:font-medium leading-5 md:leading-7 text-black-lidabro">
                    {t("Curated Collection")}
                </h4>
            </div>
            <div className="flex gap-[18px] overflow-x-auto scrollbar-none p-[10px]">
                {collections.map((collection) => (
                    <CuratedCollectionItem
                        key={collection.id}
                        title={t(collection.title)}
                        link={collection.link}
                        image={collection.image}
                    />
                ))}
            </div>
        </div>
    );
}

export default CuratedCollection;
