import { useNavigate } from "react-router-dom";
import ProgressiveImage from "../ProgressiveImage/ProgressiveImage";

function ItemsListItem({ item }) {
    const navigate = useNavigate();

    return (
        <div
            className="flex flex-col gap-2"
            onClick={() => {
                navigate(`/sport/items/${item.article}`);
            }}
        >
            <div className="min-w-[300px] min-h-[250px] flex justify-center">
                {item.images.length > 0 && (
                    <ProgressiveImage
                        highQualitySrc={item.images[0].url}
                        alt={item.images[0].altText}
                    />
                )}
            </div>
            <div className="flex flex-col font-secondary not-italic font-medium text-lg leading-7">
                <p className="text-xs leading-[18px] text-gray-lidabro-dark">
                    {item.type ? item.type : "NO TYPE"}
                </p>
                <p className="text-black-lidabro">{item.name}</p>
                {item.hasOwnProperty("discountPrice") && item.discountPrice ? (
                    <div className="flex items-center gap-2">
                        <p className="text-blue-lidabro-header">
                            ₪ {item.discountPrice.price}
                        </p>
                        <p className="text-base font-semibold text-gray-lidabro-text-2 line-through">
                            ₪ {item.actualPrice.price}
                        </p>
                    </div>
                ) : (
                    <p className="text-blue-lidabro-header">
                        ₪ {item.actualPrice.price}
                    </p>
                )}
            </div>
        </div>
    );
}

export default ItemsListItem;
