import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronRightSVG } from "../../images/SVGAssets";

function Breadcrumb({ path, last }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <div
                className="flex sm:hidden items-center"
                onClick={() => navigate(path.url)}
            >
                <p className="font-secondary text-base not-italic font-semibold leading-6 text-nowrap">
                    {t(path.name)}
                </p>
            </div>

            <div
                className="hidden sm:flex items-center"
                onClick={() => navigate(path.url)}
            >
                <p
                    className={`px-2 py-1 cursor-pointer text-nowrap${
                        last
                            ? " text-gray-lidabro-breadcrumb-bold font-semibold"
                            : ""
                    }`}
                >
                    {t(path.name)}
                </p>
                {!last ? <ChevronRightSVG /> : null}
            </div>
        </>
    );
}

export default Breadcrumb;
