function BadgeSmall({ label, bgColor, borderColor, textColor }) {
    return (
        <div
            className={`px-2 py-[2px] rounded-full border ${bgColor} ${borderColor} ${textColor} font-secondary text-xs leading-[18px] font-medium`}
        >
            <p className="flex items-center gap-1 text-nowrap">{label}</p>
        </div>
    );
}

export default BadgeSmall;
