import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import ButtonIcon from "../Controls/ButtonIcon";
import FavoriteListItems from "./FavoriteListItems";
import SportItemsCarousel from "../ItemsCarousel/SportItemsCarousel";
import { TrashSVG, ShareSVG, XClose2SVG } from "../../images/SVGAssets";
import {
    fetchItemById,
    fetchSportRelatedItems,
    fetchFavoriteBySession,
    fetchFavoriteItemsBySession,
} from "../../functions/fetch";
import { FavoriteContext } from "../../contexts/FavoriteContext";

function FavoriteSport(params) {
    //TODO: SkeletonUI
    const { favoriteItems, loading, error } = useContext(FavoriteContext);
    const [relatedItems, setRelatedItems] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchRelatedItems = async () => {
            const fetchedRelatedItems = await fetchSportRelatedItems();
            setRelatedItems(fetchedRelatedItems);
        };

        fetchRelatedItems();
    }, []);

    return (
        <>
            {loading ? (
                <div>Loading</div>
            ) : (
                <div className="flex flex-col w-full md:max-w-[650px]">
                    <div className="max-w-full hidden sm:flex items-center justify-between pt-4 pb-6 px-[45px] bg-white">
                        <p className="font-main text-2xl font-medium text-gray-lidabro-text">
                            {t("Favorites")}
                        </p>
                        <div className="flex items-center">
                            <ButtonIcon icon={<ShareSVG />} />
                            <ButtonIcon
                                icon={<XClose2SVG />}
                                callback={params.closeFavorite}
                            />
                        </div>
                    </div>

                    <div className="max-w-full flex flex-col gap-3 px-3 sm:px-[45px] pb-3 sm:pb-12 bg-white">
                        <div className="flex flex-col gap-3 sm:gap-6 rounded-[15px] sm:px-6 sm:py-6 sm:bg-gray-lidabro-select-background">
                            <p className="font-secondary text-base font-medium text-gray-lidabro-placeholder">
                                {favoriteItems.length} Product
                                {favoriteItems.length > 1 ? "s" : ""}
                            </p>
                            <FavoriteListItems items={favoriteItems} />
                            <button className="hidden sm:flex items-center justify-center px-[22px] py-4 rounded-lg border border-gray-lidabro-border-3 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] font-secondary text-sm font-semibold text-gray-lidabro-breadcrumb">
                                {t("Share your List with Friends!")}
                            </button>
                        </div>
                        <button className="sm:hidden flex items-center justify-center px-[22px] py-4 rounded-lg border border-gray-lidabro-border-3 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] font-secondary text-sm font-semibold text-gray-lidabro-breadcrumb">
                            {t("Share your List with Friends!")}
                        </button>
                        <div className="flex flex-col sm:flex-col-reverse gap-6">
                            <div className="flex">
                                <SportItemsCarousel
                                    title={"You may like"}
                                    items={relatedItems}
                                    callback={params.closeFavorite}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default FavoriteSport;
