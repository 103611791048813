import { useState, useEffect } from "react";
import classNames from "classnames";
import { modifyUrlPrefix } from "../../functions/common";

const ProgressiveImage = ({ lowQualitySrc = "", highQualitySrc, alt }) => {
    const [currentSrc, setCurrentSrc] = useState(
        lowQualitySrc != ""
            ? lowQualitySrc
            : modifyUrlPrefix(highQualitySrc, "lq_")
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const imgClasses = classNames(
        "w-auto max-h-[250px] md:h-full",
        "transition-filter duration-300 ease-in-out"
    );

    useEffect(() => {
        const img = new Image();
        img.src = highQualitySrc;
        img.onload = () => {
            setCurrentSrc(highQualitySrc);
            setIsLoaded(true);
        };
    }, [highQualitySrc]);

    return <img src={currentSrc} alt={alt} className={imgClasses} />;
};

export default ProgressiveImage;
