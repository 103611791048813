import { useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

function ControlWithIconSport({
    name,
    icon,
    action = () => {
        console.log(`Clicked ${name}`);
    },
    notificationsAmount = 0,
    isWeb = true,
}) {
    const { t } = useTranslation();
  
    const notificationsWrapperClasses = classNames(
        "absolute top-[-6px] right-[-2px] flex items-center justify-center w-4 h-4 rounded-full text-white",
        {
            "bg-white": isWeb,
        }
    )

    const notificationsAmountClasses = classNames(
        "font-secondary text-xxxs leading-7 font-bold text-black ",
        {
            "ml-4": !isWeb,
        }
    )

    return (
        <div
            className="relative flex flex-col items-center cursor-pointer"
            onClick={action}
        >
            {icon}

            {isWeb === true ? (
                    <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-white">
                        {t(name)}
                    </p>
            ) : null}

            {notificationsAmount > 0 ? (
                <div className={notificationsWrapperClasses}>
                    <p className={notificationsAmountClasses}>
                        {notificationsAmount}
                    </p>
                </div>
            ) : null}
        </div>
    );
}

export default ControlWithIconSport;
