import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SuggestionsItemSport({ suggestion }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div
            className="flex rounded-[15px] bg-white px-3 py-1 cursor-pointer"
            onClick={() => navigate(suggestion.url)}
        >
            <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-text text-nowrap">
                {t(suggestion.name)}
            </p>
        </div>
    );
}

export default SuggestionsItemSport;
