import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function BasketSubtotal(params) {
    const [discount, setDiscount] = useState(0);
    const [priceOfGoods, setPriceOfGoods] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [subtotalItems, setSubtotalItems] = useState(0);
    const { t } = useTranslation();

    function calcPriceOfProducts(items) {
        const total = items.reduce((accumulator, currentValue) => {
            if (currentValue.item) {
                return (
                    accumulator +
                    (currentValue.item.discountPrice
                        ? currentValue.item.discountPrice.price
                        : currentValue.item.actualPrice.price) *
                        currentValue.quantity
                );
            } else {
                return accumulator + 9999 * currentValue.quantity;
            }
        }, 0);
        const roundedTotal = Math.round(total * 100) / 100;
        return roundedTotal;
    }

    function calcSubtotal(items) {
        const total = calcPriceOfProducts(items);
        if (total < 0) {
            return 0;
        }
        const roundedTotal = Math.round(total * 100) / 100;
        return roundedTotal;
    }

    function calcSubtotalItems(items) {
        const total = items.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.quantity;
        }, 0);
        const roundedTotal = Math.round(total * 100) / 100;
        return roundedTotal;
    }

    function calcSubtotalDiscount(items) {
        const total = items.reduce((accumulator, currentValue) => {
            if (!currentValue.item.hasOwnProperty("discountPrice")) {
                return accumulator + 0;
            }
            return (
                accumulator +
                (currentValue.item.actualPrice.price -
                    currentValue.item.discountPrice.price) *
                    currentValue.quantity
            );
        }, 0);
        const roundedTotal = Math.round(total * 100) / 100;
        return roundedTotal;
    }

    useEffect(() => {
        setSubtotal(calcSubtotal(params.items, discount));
        setPriceOfGoods(calcPriceOfProducts(params.items));
        setSubtotalItems(calcSubtotalItems(params.items));
        setDiscount(calcSubtotalDiscount(params.items));
    });

    return (
        <>
            <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                    <p className="font-secondary text-base font-medium text-gray-lidabro-text">
                        {t("Subtotal")} ({subtotalItems}{" "}
                        {params.items.length > 1 ? t("items") : t("item")})
                    </p>
                    <p className="font-secondary text-base font-medium text-gray-lidabro-text">
                        {subtotal} ₪
                    </p>
                </div>
                <div className="flex gap-3">
                    <p className="font-secondary text-xs font-medium leading-[18px] text-gray-lidabro-placeholder text-nowrap">
                        {t("Price of products")}
                    </p>
                    <div className="flex grow border-b border-dashed border-gray-lidabro-border-2"></div>
                    <p className="font-secondary text-sm font-medium text-gray-lidabro-placeholder">
                        {priceOfGoods} ₪
                    </p>
                </div>
                <div className="flex gap-3">
                    <p className="font-secondary text-sm font-medium text-green-lidabro-text">
                        {t("Discount")}
                    </p>
                    <div className="flex grow border-b border-dashed border-green-lidabro-text"></div>
                    <p className="font-secondary text-sm font-medium text-green-lidabro-text">
                        -{discount} ₪
                    </p>
                </div>
            </div>
        </>
    );
}

export default BasketSubtotal;
