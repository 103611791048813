import FooterLogo from "./FooterLogo";
import FooterLink from "./FooterLink";

function FooterSport(params) {
    return (
        <div className="flex flex-col items-center justify-center px-[160px] py-10 gap-3 bg-gray-lidabro-text">
            <FooterLogo />
            <div className="flex xmd:flex-row flex-col gap-[12px] font-secondary text-sm not-italic font-medium leading-5 text-white text-center text-nowrap">
                <FooterLink link="/" text="What is Lidabro?" />
                <FooterLink
                    link="mailto:requests@lidabro.com"
                    text="Contact Us"
                />
                <FooterLink
                    link="https://lidabro.notion.site/Terms-of-use-1519311096f68098a9a1e1be61615696"
                    text="Terms of Use"
                />
                <FooterLink
                    link="https://lidabro.notion.site/Privacy-Policy-14f9311096f680fea00ecdc5b252f534"
                    text="Privacy Policy"
                />
            </div>
        </div>
    );
}

export default FooterSport;
