import { FooterLogoSVG } from "../../images/SVGAssets";

function FooterLogo(){
    return (
        <div className="flex items-center gap-[3.377px] font-main text-xl font-semibold text-white">
            <FooterLogoSVG />
            <p>Lidabro</p>
        </div>
    )
}

export default FooterLogo;