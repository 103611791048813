import ItemVariant from "./ItemVariant";

function ItemVariants({ variants, selectedId = '' }) {
    
    return (
        <>
            <div className="flex gap-[10px] overflow-x-auto scrollbar-none">
                {variants.map((variant) => (
                    <ItemVariant
                        isSelected={selectedId === variant.id}
                        key={variant.id}
                        variant={variant}
                    />
                ))}
            </div>
        </>
    );
}

export default ItemVariants;
