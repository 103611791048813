import { useTranslation } from "react-i18next";
import { EyeSVG } from "../../images/SVGAssets";

function StreamSeeLiveHelp({ eyezon }) {
    const { i18n, t } = useTranslation();

    return (
        <div className="relative flex flex-col items-center justify-center px-6 py-10 gap-6 rounded-lg bg-lightgray">
            <div
                className="absolute inset-0 bg-center bg-cover rounded-lg"
                style={{
                    backgroundImage: "url('/images/banners/basket_banner.png')",
                    opacity: 0.1,
                }}
            ></div>

            <div className="flex flex-col gap-2 font-secondary text-sm font-medium text-black-lidabro-select-text text-center z-10">
                <p>
                    {t(
                        "Not sure what to choose? Let our professional seller guide you through our catalog and help you find exactly what you need."
                    )}
                </p>
                <p>{t("Join our live stream now!")}</p>
            </div>
            <button
                data-eyezon={i18n.language === "en" ? "232" : "235"}
                data-eyezon-target={eyezon.target}
                data-eyezon-title={eyezon.title}
                data-eyezon-put-in-cart
                className="flex items-center justify-center gap-[10px] py-4 px-[22px] rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-base font-semibold z-10"
                onClick={() => {
                    ym(98895916, "reachGoal", "StreamOn");
                }}
            >
                <EyeSVG fill="white" />
                {t("Join Stream with Seller")}
            </button>
        </div>
    );
}

export default StreamSeeLiveHelp;
