import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function ItemsFilterPrice({ items, setFilteredItems }) {
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        const filtered = items.filter((item) => {
            const price = item.discountPrice
                ? item.discountPrice.price
                : item.actualPrice.price;
            const meetsMin = minPrice ? price >= parseFloat(minPrice) : true;
            const meetsMax = maxPrice ? price <= parseFloat(maxPrice) : true;
            return meetsMin && meetsMax;
        });

        setFilteredItems(filtered);
    }, [minPrice, maxPrice, items, setFilteredItems]);

    return (
        <div className="flex flex-col gap-3 px-[9px] py-5">
            <p className="font-secondary text-sm font-medium leading-5 text-gray-lidabro-text">
                {t("Price")}, ₪
            </p>
            <div className="flex gap-3">
                <div className="w-1/2">
                    <input
                        className="w-full max-w-[150px] px-[14px] py-[10px] border border-gray-lidabro-border-2 rounded-lg bg-white box-border overflow-hidden flex-shrink-0"
                        placeholder={`${t("priceFrom")} ${minPrice || 0}`}
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                        type="number"
                    />
                </div>
                <div className="w-1/2">
                    <input
                        className="w-full max-w-[150px] px-[14px] py-[10px] border border-gray-lidabro-border-2 rounded-lg bg-white box-border overflow-hidden flex-shrink-0"
                        placeholder={`${t("priceTill")} ${maxPrice || 9999}`}
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                        type="number"
                    />
                </div>
            </div>
        </div>
    );
}

export default ItemsFilterPrice;
