import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CategoryBadge from "./CategoryBadge";
import { fetchCategoryChildrenByUrl } from "../../functions/fetch";

function Subcategory({ path }) {
    const [descendantCategories, setDescendantCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchDescendantCategories = async () => {
            const fetchedDescendantCategories =
                await fetchCategoryChildrenByUrl(path);
            setDescendantCategories(fetchedDescendantCategories);
            setLoading(false);
        };

        fetchDescendantCategories();
    }, [path]);

    const handleNavigate = (url) => {
        navigate(url);
    };

    return (
        <>
            {loading ? (
                <div>{t("Loading")}...</div>
            ) : (
                <>
                    <div className="flex md:hidden p-3 gap-3 overflow-x-auto scrollbar-none">
                        {descendantCategories.children.map((category) =>
                            category.children.map((category) => (
                                <CategoryBadge
                                    key={category._id}
                                    label={t(category.name)}
                                    icon={category.icon}
                                    onClick={() => handleNavigate(category.url)}
                                />
                            ))
                        )}
                    </div>

                    <div className="hidden md:flex lg:hidden flex-col px-12 py-10 gap-[18px] border-b border-gray-lidabro-border-2">
                        <p className="font-secondary text-lg not-italic font-medium leading-7">
                            {t("Find what you need")}
                        </p>
                        <div className="flex items-center justify-center gap-3  flex-wrap">
                            {descendantCategories.children.map((category) => (
                                <CategoryBadge
                                    key={category._id}
                                    label={t(category.name)}
                                    icon={category.icon}
                                    onClick={() => handleNavigate(category.url)}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="hidden lg:flex flex-col gap-[18px] px-[60px] xl:px-[120px] py-10 border-b border-gray-lidabro-border-2">
                        {descendantCategories.children.map((category) => (
                            <div
                                key={category._id}
                                className="flex flex-col xl:flex-row xl:items-center gap-[18px] xl:gap-0"
                            >
                                <p
                                    className="font-secondary text-lg not-italic font-medium leading-7 text-black-lidabro xl:min-w-[250px]"
                                    onClick={() => handleNavigate(category.url)}
                                >
                                    {t(category.name)}
                                </p>
                                <div className="flex gap-3 overflow-x-auto scrollbar-none">
                                    {category.children.map((category) => (
                                        <CategoryBadge
                                            key={category._id}
                                            label={t(category.name)}
                                            icon={category.icon}
                                            onClick={() =>
                                                handleNavigate(category.url)
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
}

export default Subcategory;
