import { useTranslation } from "react-i18next";

function AboutUs(params) {
    const { t } = useTranslation();

    return (
        <div className="flex px-3 py-[10px] items-center rounded-lg">
            <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-text cursor-pointer"
            >
                {t("What is Lidabro?")}
            </a>
        </div>
    );
}

export default AboutUs;
