import { useTranslation } from "react-i18next";
function FooterLink({ link, text }) {
  const { t } = useTranslation();
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="py-[10px] px-[12px] cursor-pointer"
    >
      {t(text)}
    </a>
  );
}

export default FooterLink;
