import { useSearch } from "../../hook/useSearch";
import { useTranslation } from "react-i18next";

function MobileSearch(params) {

    const {handleSubmit, setQuery, suggestions, query} = useSearch();
    const { t } = useTranslation();

    const {onSuggestionSubmitCallBack} = params;

    const handleSuggestionSubmit = (e, suggestion) => {
        handleSubmit(e, suggestion)
        if(onSuggestionSubmitCallBack) {
            onSuggestionSubmitCallBack();
        }
    }

    return (
        <>
            <div className="w-full flex flex-col justify-center gap-6 px-6 py-10">
                <input
                    className="px-[10px] py-[9px] outline-none border-b border-gray-lidabro-text"
                    placeholder={t("I want to buy")}
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleSuggestionSubmit(e, query)
                        }
                    }}
                />
                <div className="flex flex-col justify-center font-secondary text-sm font-medium text-gray-lidabro-text">
                    {suggestions.map((suggestion) => {
                        return (
                            <button
                                className="flex justify-start"
                                onClick={(e) => handleSuggestionSubmit(e, suggestion)}
                            >
                                <p className="px-[10px] py-[9px]">{suggestion}</p>
                            </button>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default MobileSearch;
