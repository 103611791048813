function CategoryBadge(params) {
    return (
        <>
            <div
                className="flex md:hidden flex-col items-center justify-center px-3 py-[9px] rounded-[15px] bg-gray-lidabro-select-background cursor-pointer"
                onClick={params.onClick}
            >
                <div className="flex p-3 items-center justify-center gap-2">
                    <img
                        className="min-w-[25px] min-h-[25px] max-w-[25px] max-h-[25px] object-scale-down"
                        src={params.icon}
                        alt={params.label}
                    />
                </div>
                <p className="font-secondary text-sm not-italic font-normal leading-5 text-center text-black-lidabro whitespace-nowrap">
                    {params.label}
                </p>
            </div>

            <div
                className="hidden md:flex items-center justify-center px-3 py-[9px] rounded-[15px] bg-gray-lidabro-select-background cursor-pointer"
                onClick={params.onClick}
            >
                <div className="flex px-3 py-[9px] items-center gap-[6px]">
                    <img
                        className="min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] object-scale-down"
                        src={params.icon}
                        alt={params.label}
                    />
                </div>
                <p className="font-secondary text-sm not-italic font-normal leading-5 text-center text-black-lidabro whitespace-nowrap">
                    {params.label}
                </p>
            </div>
        </>
    );
}

export default CategoryBadge;
