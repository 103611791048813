import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function RedirectNotification({ redirectUrl }) {
    const [counter, setCounter] = useState(5);
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setInterval(() => {
            setCounter((prevCounter) => {
                if (prevCounter <= 1) {
                    clearInterval(timer);
                    window.location.href = redirectUrl;
                    return 0;
                }
                return prevCounter - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [redirectUrl]);

    return (
        <div className="absolute top-[70px] w-1/2 flex flex-col gap-3 items-center justify-center rounded-lg border border-gray-lidabro-border-3 p-3 bg-white">
            <p className="font-secondary text-base">
                {t(
                    "To complete your purchase, you'll be redirected to Bodymania's website for payment and delivery arrangements."
                )}{" "}
                {t("By proceeding, you agree to Bodymania's")}{" "}
                <a
                    className="text-blue-600 visited:text-purple-600"
                    href="https://bodymania.co.il/%d7%aa%d7%a0%d7%90%d7%99-%d7%a9%d7%99%d7%9E%d7%95%d7%a9-%d7%91%d7%90%d7%aa%d7%a8/"
                >
                    {t("terms of use")}
                </a>{" "}
                {t("and")}{" "}
                <a
                    className="text-blue-600 visited:text-purple-600"
                    href="https://bodymania.co.il/%d7%aa%d7%a0%d7%90%d7%99-%d7%a9%d7%99%d7%9E%d7%95%d7%a9-%d7%91%d7%90%d7%aa%d7%a8/"
                >
                    {t("delivery policy")}
                </a>
            </p>
            <p className="font-secondary text-base">
                {t("You will be redirected in")}{" "}
                <span className="text-gray-lidabro-text font-bold">
                    {counter}
                </span>{" "}
                {t("timer second", { counter })}
            </p>
        </div>
    );
}

export default RedirectNotification;
