import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ItemsFilterPrice from "./ItemsFilterPrice";
import ItemsFilter from "./ItemsFilter";
import { fetchFiltersByCategory } from "../../functions/fetch";

function ItemsFilters({ items, setFilteredItems, category }) {
    const filters = fetchFiltersByCategory(category);
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.size > 0) {
            const fetchedfilteredItems = items.filter((item) => {
                return [...searchParams].every(([key, value]) => {
                    // Check if the item has the key before applying the filter condition
                    if (item.hasOwnProperty(key)) {
                        return String(item[key]).includes(value);
                    }
                    return true; // Ignore this search param if the item doesn't have the key
                });
            });
            setFilteredItems(fetchedfilteredItems);
        } else {
            setFilteredItems(items);
        }
    }, [location.pathname, location.search]);

    return (
        <div className="w-full flex flex-col px-3 border-t border-r border-gray-lidabro-border-3">
            <ItemsFilterPrice
                items={items}
                setFilteredItems={setFilteredItems}
            />
            {/* {filters.map((filter) => (
                <ItemsFilter
                    key={filter.name}
                    name={filter.name}
                    children={filters}
                />
            ))} */}
        </div>
    );
}

export default ItemsFilters;
