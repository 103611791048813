import ButtonIcon from "../Controls/ButtonIcon";
import { BasketSVG, BasketSVG3, HeartSVG } from "../../images/SVGAssets";
import useSuccessMessage from "../../hook/useSuccessMessage";
import { FavoriteContext } from "../../contexts/FavoriteContext";
import { BasketContext } from "../../contexts/BasketContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { truncateStr } from "../../functions/common";

function FavoriteListItem({ item }) {
    const { toggleItem } = useContext(FavoriteContext);
    const { addItem } = useContext(BasketContext);
    const { getSuccessMessage, trigger } = useSuccessMessage({
        type: "basket",
    });
    const { i18n, t } = useTranslation();

    const handleAddToBasketClick = (e) => {
        e.stopPropagation();
        addItem(item, 1);
        trigger(item);
    };

    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        toggleItem(item);
    };

    return (
        <>
            {item && (
                <div className="flex items-center px-[10px] py-3 gap-3">
                    <div
                        className="cursor-pointer"
                        onClick={handleFavoriteClick}
                    >
                        <HeartSVG filled={true} />
                    </div>
                    <div className="flex items-center justify-center w-[50px] sm:w-[100px] min-w-[70px] h-[50px] sm:h-[100px] min-h-[70px]">
                        {item.images.length > 0 ? (
                            <img
                                className="w-full object-scale-down"
                                src={item.images[0].url}
                                alt={item.images[0].alt}
                            />
                        ) : null}
                    </div>
                    <div className="w-full flex pl-3 py-3 gap-3 items-center">
                        <div className="flex flex-col py-3 gap-[6px] grow">
                            <div className="flex flex-col">
                                <p className="font-secondary text-xs font-medium leading-[18px] text-nowrap text-gray-lidabro-select-remove">
                                    {item.type}
                                </p>
                                <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap text-gray-lidabro-text">
                                    {i18n.language === "en"
                                        ? truncateStr(item.name, 40)
                                        : truncateStr(
                                              item[`name_${i18n.language}`],
                                              40
                                          ) || truncateStr(item.name, 40)}
                                </p>
                                {item.hasOwnProperty("discountPrice") ? (
                                    <div className="flex gap-2 items-center">
                                        <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                                            ₪ {item.discountPrice.price}
                                        </p>
                                        <p className="font-secondary text-xxs font-semibold leading-4 text-nowrap text-gray-lidabro-text-2 line-through">
                                            ₪ {item.actualPrice.price}
                                        </p>
                                    </div>
                                ) : (
                                    <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                                        ₪ {item.actualPrice.price}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex sm:hidden items-center justify-center cursor-pointer"
                        onClick={handleAddToBasketClick}
                    >
                        <BasketSVG />
                    </div>
                    <div
                        className="hidden sm:flex px-[22px] py-4 items-center justify-center rounded-lg bg-gray-lidabro-breadcrumb shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] cursor-pointer"
                        onClick={handleAddToBasketClick}
                    >
                        <BasketSVG3 />
                    </div>
                    {getSuccessMessage()}
                </div>
            )}
        </>
    );
}

export default FavoriteListItem;
