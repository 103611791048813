import { useNavigate } from "react-router-dom";
import classNames from "classnames";

function ItemVariant({ variant, isSelected = false }) {
    const navigate = useNavigate();
    const buttonClasses = classNames(
        "flex items-center justify-center px-6 py-[10px]  rounded-lg font-secondary text-base font-normal leading-6 text-gray-lidabro-placeholder bg-white",
        isSelected
            ? "border border-blue-lidabro-select-focused"
            : "border border-gray-lidabro-border-3"
    );

    return (
        <>
            <button
                className={buttonClasses}
                onClick={() => {
                    navigate(`/sport/items/${variant.article}`);
                }}
            >
                {variant.value}
            </button>
        </>
    );
}

export default ItemVariant;
