export const handleError = (error) => {
    // Check if the error has a response (Axios-specific)
    if (error.response) {
        const { status, data } = error.response;
        console.error(`Server Error (${status}):`, data?.message || data);

        // Map specific error codes to user-friendly messages
        switch (status) {
            case 400:
                return "Bad Request. Please check your input.";
            case 401:
                return "Unauthorized. Please log in.";
            case 404:
                return "The requested resource was not found.";
            case 500:
                return "Server error. Please try again later.";
            default:
                return "An unexpected error occurred. Please try again.";
        }
    } else if (error.request) {
        // No response received
        console.error("No response from server:", error.request);
        return "Unable to connect to the server. Please check your network connection.";
    } else {
        // Error during setup
        console.error("Error in request setup:", error.message);
        return "An unexpected error occurred while processing your request.";
    }
};
