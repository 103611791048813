import { useState } from "react";
import { SearchSVG } from "../../images/SVGAssets";
import { useSearch } from "../../hook/useSearch";
import classNames from "classnames";

function SearchBarSport({ placeholderText }) {
    const { handleSubmit, setQuery, suggestions, query } = useSearch();
    const [isHovered, setIsHovered] = useState(false);

    const inputClasses = classNames(
        "w-full h-11 p-2",
        "font-secondary text-sm font-medium",
        "bg-black-lidabro-light focus:outline-none text-gray-lidabro-placehorder-search",
        isHovered && "placeholder:text-gray-lidabro-placeholder-2"
    );

    return (
        <form
            className="w-full flex items-center h-11"
            onSubmit={handleSubmit}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="w-full flex items-center justify-center bg-black-lidabro-light rounded-lg border border-gray-lidabro-border-4 hover:border-gray-lidabro-border-5 px-5">
                <div onClick={handleSubmit}>
                    <SearchSVG hovered={isHovered} />
                </div>
                <input
                    className={inputClasses}
                    type="search"
                    id="search"
                    placeholder={placeholderText}
                    autoComplete="off"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    list="autocomplete-results"
                />
                <datalist id="autocomplete-results">
                    {suggestions.map((suggestion, index) => (
                        <option value={suggestion} key={index} />
                    ))}
                </datalist>
            </div>
        </form>
    );
}

export default SearchBarSport;
