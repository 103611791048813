import { useTranslation } from "react-i18next";
import { MapPin4SVG } from "../../images/SVGAssets";

function LocationSport(params) {
    const { t } = useTranslation();

    return (
        <div className="flex px-3 py-[10px] items-center gap-[6px] rounded-lg">
            <MapPin4SVG />
            <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-text">
                {t("Israel")}
            </p>
        </div>
    );
}

export default LocationSport;
