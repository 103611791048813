import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ItemsFilters from "../../components/ItemsFilters/ItemsFilters";
import BannerItemsSport from "../../components/Banner/BannerItemsSport";
import Subcategory from "../../components/Category/Subcategory";
import ControlPanelSport from "../../components/ControlPanel/ControlPanelSport";
import ItemsList from "../../components/ItemsList/ItemsList";
import {
    fetchItemsByCategoryUrl,
    fetchBreadcrumbByCategoryUrl,
    fetchItemsByBrandUrl,
    fetchBreadcrumbByBrandUrl,
    fetchSearchItems,
} from "../../functions/fetch";

function ItemsSport() {
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [breadcrumbPath, setBreadcrumbPath] = useState([]);
    const { t } = useTranslation();

    const fetchItems = async () => {
        setLoading(true);
        let fetchedItems, fetchedBreadcrumb;
        if (location.pathname.includes("/brands/")) {
            fetchedItems = await fetchItemsByBrandUrl(location.pathname);
            fetchedBreadcrumb = await fetchBreadcrumbByBrandUrl(
                location.pathname
            );
        } else if (location.pathname.includes("/search")) {
            const queryParams = new URLSearchParams(location.search);
            const query = queryParams.get("query");
            fetchedItems = await fetchSearchItems(query);
            fetchedBreadcrumb = await fetchBreadcrumbByCategoryUrl("/sport");
        } else {
            fetchedItems = await fetchItemsByCategoryUrl(location.pathname);
            fetchedBreadcrumb = await fetchBreadcrumbByCategoryUrl(
                location.pathname
            );
        }

        setItems(fetchedItems);
        setFilteredItems(fetchedItems);
        setBreadcrumbPath(fetchedBreadcrumb);
        setLoading(false);
    };

    // Fetch items when the component mounts or location changes
    useEffect(() => {
        fetchItems();
    }, [location.pathname, location.search]);

    return (
        <>
            {loading ? (
                <div>{t("Loading")}</div>
            ) : (
                <>
                    <Breadcrumbs path={breadcrumbPath} />
                    {location.pathname.includes("/brands/") ||
                    location.pathname.includes("/search") ? null : (
                        <Subcategory path={location.pathname} />
                    )}
                    <div className="flex">
                        <div className="hidden md:flex md:min-w-[200px] lg:min-w-[300px] lg:max-w-1/3">
                            <ItemsFilters
                                category={
                                    breadcrumbPath[breadcrumbPath.length - 1]
                                }
                                items={items}
                                setFilteredItems={setFilteredItems}
                            />
                        </div>
                        <div className="overflow-auto flex-grow">
                            <BannerItemsSport
                                category={
                                    breadcrumbPath[breadcrumbPath.length - 1]
                                }
                            />
                            <ControlPanelSport amount={filteredItems.length} />
                            <ItemsList items={filteredItems} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default ItemsSport;
